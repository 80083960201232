import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Page from '../components/Page';
import Header from '../components/Header';
import Content from '../components/styles/Content';
import PageTitle from '../components/styles/PageTitle';

const BooksPage = ({ data }) => (
  <Page>
    <>
      <Helmet title="Books" />
      <Header books />
      <Content>
        <PageTitle>Books</PageTitle>
        <p>
          I've decided to keep track of the books I've been reading. List
          starting from winter 2017, most recent first.
        </p>
        <div style={{ height: '1.5rem' }} />
        {[...data.allBooks.edges].reverse().map(edge => (
          <li style={{ fontSize: '1.9rem' }} key={edge.node.id}>
            {edge.node.title} by {edge.node.authors.join(', ')}
          </li>
        ))}
      </Content>
    </>
  </Page>
);

export const query = graphql`
query allBooks {
  allBooks {
    edges {
      node {
        id
        title
        authors
      }
    }
  }
}
`;
export default BooksPage;
